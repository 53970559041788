.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: hsla(0, 0%, 0%, 0.4);
  grid-row: 2 / 5;
  grid-column: 1/4;
}

.modal-card p {
  margin: 2px 0;
}

.modal-card h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.modal-card input {
  margin-top: 10px;
}

.esri-popup__header {
  font-size: 16px;
}

.modal-card textarea {
  margin-top: 10px;
  width: 300px;
}

textarea {
  height: 100px;
  font-family: 'Rubik';
  font-size: 16px;
}

.modal-card label {
  margin-top: 15px;
}

.modal-card {
  z-index: 2;
  box-shadow: 0 0 8px -2px hsl(134, 31%, 15%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 8px;
  background-color: #eaede9;
}

.modal-card img {
  border-radius: 50%;
  background-color: skyblue;
  margin: 15px;
}

.five-day-forecast {
  display: flex;
}

.day {
  padding: 10px;
}

.day img {
  height: 30px;
  margin: 5px;
}

.card-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.favorite-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.modal-card a {
  padding: 6px;
}