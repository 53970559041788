.map-page {
  display: grid;
  grid-template-columns: 3% auto 3%;
  grid-template-rows: 3rem 2.5rem auto 1.5rem 3rem;
  width: 100%;
  height: 100%;
}

.map-instructions {
  grid-row-start: 2;
  grid-column-start: 2;
  align-self: center;
}

.esri-view-surface {
  border-radius: 5px;
  box-shadow: 0 0 3px #28190e;
}

.esri-attribution__sources,
.esri-attribution__powered-by {
  display: none;
}

.map-page-map {
  height: auto;
  width: auto;
  grid-row: 3;
  grid-column: 2;
}