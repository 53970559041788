@import url('https://fonts.googleapis.com/css2?family=Rubik:ital@0;1&family=Sniglet&display=swap');

* {
  box-sizing: border-box;
}

.active-link {
  border-radius: 5px;
  background-color: #28190e;
  padding: 5px;
  box-shadow: 0 0 3px hsl(134, 31%, 15%);
}

button {
  font-family: 'Rubik';
  font-size: 16px;
  padding: 3px;
}

button:hover {
  cursor: pointer;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  height: 100vh;
}

a {
  text-decoration: none;
}

header h1 {
  font-family: 'Sniglet', cursive;
  font-size: 2.2rem;
}

body {
  font-family: 'Rubik', sans-serif;
  background: linear-gradient(hsl(218, 53%, 80%), hsl(294, 26%, 32%));
}

#header {
  z-index: 1;
  padding: 0 30px;
  width: 100vw;
  height: 3rem;
  background-color: #2a5033;
  box-shadow: 0 0 4px #1a3220;
  color: #eaede9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#header a {
  color: hsl(105, 10%, 92%);
}

#header ul {
  display: flex;
  font-size: 18px;
  list-style: none;
}

#header li {
  padding: 5px;
  margin-right: 15px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: -2px 0 4px #28190e;
  height: 3rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #28190e;
  color: #eaede9;
}

footer a {
  color: #eaede9;
}

.bug-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  color: #28190e;
  background-color: #eaede9;
  margin-top: -150px;
  margin-left: -150px;
  border-radius: 5px;
  box-shadow: 0 0 5px -2px #1a3220;
}

.bug-form label {
  margin: 5px;
}

.bug-form h2 {
  font-size: 24px;
  text-align: center;
}

.bug-form input[type='submit'] {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  padding: 3px;
  margin: 10px 0;
}