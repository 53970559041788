.about-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-content {
  width: 1000px;
  height: 100%;
  background-color: #eaede9;
  display: flex;
  flex-flow: row wrap;
}

.about-page img {
  width: 300px;
  height: 300px;
  margin: 30px;
}

.about-page p {
  margin: 15px;
}

.dispersed-ps .narrow {
  width: 400px;
  margin: 30px;
}