.form-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  background-color: #eaede9;
  width: 500px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 5px -2px hsl(134, 31%, 15%);
}

.sign-in {
  display: flex;
  flex-direction: column;
}

.form-container h2 {
  font-size: 30px;
  text-align: center;
}

.form-container label {
  margin: 10px 0;
}

.form-container input {
  padding: 5px;
  font-size: 16px;
  font-family: "Rubik";
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 4px -1px hsl(134, 31%, 15%);
}

.form-container input[type="submit"] {
  padding: 8px 20px;
  margin: 8px auto;
  background-color: #2a5033;
  color: #eaede9;
}

.form-container button {
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 4px -1px hsl(134, 31%, 15%);
  padding: 8px;
  background-color: #2a5033;
  color: #eaede9;
}

.toggler {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.error-message {
  color: red;
  padding: 5px 0px;
}
