.favorites {
  display: grid;
  grid-template-rows: 3rem 1.5rem auto 1.5rem 3rem;
  grid-template-columns: 1.5% 24rem 1.5% auto 1.5%;
  width: 100%;
  height: 100%;
}

.favorites-list {
  height: 100%;
  padding: 10px;
  grid-column: 2;
  grid-row: 2 / 5;
  background-color: #EAEDE9;
  box-shadow: 0 0 6px -2px hsl(134, 31%, 15%);
  overflow: auto;
}

.favorites-map {
  height: auto;
  width: auto;
  grid-column: 4 / 5;
  grid-row: 3 /4;
}

.site-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px -2px hsl(134, 31%, 15%);
  margin-bottom: 10px;
}

.site-info button {
  background-color: #2a5033;
  box-shadow: 0 0 5px -2px hsl(134, 31%, 15%);
  color: #EAEDE9;
  border: none;
  border-radius: 5px;
  padding: 0 10px;
}

.lat-lng {
  margin-bottom: 8px;
}

.note {
  margin-bottom: 8px;
  width: 100%;
}

.site-info a {
  color: #28190e;
  margin-left: auto;
}

.site-info a:hover {
  background-color: hsla(134, 31%, 24%, 10%);
  border-radius: 5px;
  box-shadow: 0 0 5px hsla(134, 58%, 26%, 20%);
}

.favorites-warning {
  font-size: 60px;
  grid-row: 3;
  color: #28190e;
}

.favorites .update {
  color: #28190e;
  background-color: transparent;
  box-shadow: 0 0 5px hsla(134, 58%, 26%, 20%);
}